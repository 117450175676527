define("adt-wss-common/services/account-data", ["exports", "adt-wss-common/utils/http-resources", "adt-wss-common/utils/myadt-helpers", "adt-wss-common/utils/fp"], function (_exports, _httpResources, _myadtHelpers, _fp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _temp;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const clearCacheArgs = {
    accountDocsResponse: {},
    allActivity: null,
    cancellationInfo: null,
    accountInfo: null
  };

  /**
   * API container and data store for user's general account data
   * @module Services/AccountData
   * @prop {Object[]} allActivity list of user events, only for those with `paidDataServices` or `paidDataServicesAdmin` permission
   * @prop {ContractRider[]} riders addendums to user's service contract
   * @prop {MonitoringContract[]} contracts active service contracts
   * @prop {InspectionReport[]} inspections site inspection reports
   * @prop {AccountDocument[]} customerDocList
   * @prop {Object} cancellationInfo
   * @prop {Boolean} cancellationInfo.pendingCancellation
   * @prop {String} cancellationInfo.cancelEffectiveDate ISO String
   * @prop {String} cancellationInfo.cancelType
   */
  let AccountData = (_class = (_temp = class AccountData extends Ember.Service.extend((0, _myadtHelpers.clearCache)(clearCacheArgs)) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "apiService", _descriptor, this);
      _initializerDefineProperty(this, "accountDocsResponse", _descriptor2, this);
      _initializerDefineProperty(this, "allActivity", _descriptor3, this);
      _initializerDefineProperty(this, "cancellationInfo", _descriptor4, this);
      _initializerDefineProperty(this, "accountInfo", _descriptor5, this);
    }
    get customerDocList() {
      return this.accountDocsResponse.customerDocList;
    }
    get riders() {
      return (this.customerDocList || []).filter(d => /contractamendment/i.test(d.docType));
    }
    get contracts() {
      return {
        contracts: (this.customerDocList || []).filter(d => /^contract/i.test(d.docType)),
        contractMsg: this.accountDocsResponse.contractMsg
      };
    }
    get inspections() {
      return (this.customerDocList || []).filter(d => /inspection/i.test(d.docType));
    }
    get renewalDocumentPendingAlert() {
      return this.accountDocsResponse.renewalDocumentPendingAlert;
    }

    /**
     * Populates `riders`, `contracts`, and `inspections`
     * @instance
     * @type {ServiceCall}
     *
     * @return {ApiResponse}
     */
    getAccountDocuments() {
      return this.apiService.request({
        resource: _httpResources.myADT.getAccountDocuments,
        data: {
          docTypes: 'CONTRACT,CONTRACTAMENDMENT,INSPECTIONREPORT'
        }
      }).then(({
        data
      }) => {
        const customerDocList = (data.customerDocList || []).map(d => {
          const fileType = _myadtHelpers.default.getQueryString('content-type', d.emailUri);
          let {
            docSize
          } = d;
          if (d.docSize > 999 && d.docSize < 1024000) {
            docSize = "".concat((d.docSize / 1024).toFixed(2), " KB");
          } else if (d.docSize >= 1024000) {
            docSize = "".concat((d.docSize / 1048576).toFixed(2), " MB");
          } else {
            docSize += 'B';
          }
          return (0, _fp.omix)(d, {
            docSize,
            fileType
          });
        });
        return this.set('accountDocsResponse', (0, _fp.omix)(data, {
          customerDocList
        }));
      });
    }
    getAccountDocumentsByType(type) {
      return this.getAccountDocuments().then(() => this.get(type));
    }

    /**
     * Delivers all activity in an XLS document
     * @instance
     * @type {ServiceCall}
     *
     * @return {ApiResponse}
     */
    getExcelReport() {
      return this.apiService.myADT.exportAccountActivity();
    }

    /**
     * Populates `allActivity`
     * @instance
     * @type {ServiceCall}
     *
     * @return {ApiResponse}
     */
    getAccountActivity() {
      return this.apiService.myADT.getAccountActivity().then(({
        data
      }) => this.set('allActivity', data));
    }
    getCancellationInfo() {
      return this.apiService.myADT.getCancellationInfo().then(({
        data
      }) => this.set('cancellationInfo', data));
    }
    getAccountInfo() {
      return this.apiService.myADT.getAccountInfo().then(({
        data
      }) => this.set('accountInfo', data));
    }
  }, _temp), _descriptor = _applyDecoratedDescriptor(_class.prototype, "apiService", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "accountDocsResponse", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "allActivity", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cancellationInfo", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "accountInfo", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _class);
  _exports.default = AccountData;
});