define("adt-wss-common/services/api-service", ["exports", "fetch", "adt-wss-common/classes/ajax-response", "adt-wss-common/classes/memoize", "adt-wss-common/utils/fp", "adt-wss-common/utils/http-resources", "adt-wss-common/utils/myadt-helpers"], function (_exports, _fetch, _ajaxResponse, _memoize, _fp, _httpResources, _myadtHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.replaceParamsToValues = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _temp;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const contractModeSwitch = function () {
    const contractMode = this.toggleProperty('contractMode');
    window.sessionStorage.setItem('contractMode', Number(contractMode));
    window.contractMode = contractMode;
    return contractMode;
  };
  const POST = 'POST';

  /**
   * make wrapper function over request function
   * @module Services/ApiService
   * @argument {Object} src - Resorce object from HttpResorces (myADT or chudly) inner object
   * @argument {Object} scope - Context of api-service
   * @return {Object} Object of API functions
   * @example
   * // before
   * apiService.request({
   * 	resource: httpResources.myADT.updateName,
   * 	data: { firstName: 'Tony', lastName: 'Stark' }
   * })
   * 	.then(success);
   *
   * // after
   * apiService.myADT.updateName({ firstName: 'Tony', lastName: 'Stark' })
   * 	.then(success);
   *
   * // middleUsage
   * apiService.myADT.updateName(
   * 	{ firstName: 'Tony', lastName: 'Stark'},
   * 	{ headers: { searchId : 'abrakadabra' } }
   * ).then(success);
   */
  const makeApiMethod = (src, scope) => {
    const dest = {};
    Object.keys(src).forEach(resourceName => {
      const resource = src[resourceName];
      /**
       * API method wrapper
       * @argument {*} data - any data which we should pass to server
       * @argument {Object} [fullOptions] - if use this then data argument will be ignored and used as old option
       */
      dest[resourceName] = function (data, fullOptions) {
        if (fullOptions) {
          const newData = (0, _fp.omix)(data, fullOptions.data);
          return scope.request((0, _fp.omix)(fullOptions, {
            resource: fullOptions.resource || resource,
            body: newData
          }));
        }
        return scope.request({
          resource,
          body: data
        });
      };
    });
    return dest;
  };

  /**
   * property name in the body of request will be automalically changed if
   * resource has the same name in params array and in the URL
   * @module Services/ApiService
   * @param {HTTPRequest} options
   * @example
   * //http-resources some resource
   * {
   *  getUsers: {
   *    url: '/users/:userId',
   *   	method: POST,
   *    params: ['userId']
   *  },
   *  getCats: {
   *    url: '/cat/:catId',
   *   	method: POST,
   *    params: ['catId'],
   *    keepData: ['catId']
   *  }
   * }
   *
   * this.get('request').myADT.getUsers({userId: 11}); // { url: 'users/11', body: {}}
   * this.get('request').myADT.getCats({catId: 11}); // { url: 'cats/22', body: { catId: 22}}
   */
  const replaceParamsToValues = (res, opts) => {
    const resource = (0, _fp.omix)(res);
    const options = (0, _fp.omix)(opts, {
      url: resource.url
    });
    const {
      body = {},
      params = {}
    } = options;
    (resource.params || []).forEach(param => {
      const {
        url
      } = options;
      const isParamInData = param in body;

      // params object will be deprecated
      const isParamInParams = param in params;
      let partOfURL = null;
      if (isParamInData) {
        partOfURL = body[param];
      } else if (isParamInParams) {
        partOfURL = params[param];
      } else {
        console.error("Parameter is required for ".concat(url, ". Please check your \"body\" or \"params\" for ").concat(param, " key"));
      }
      options.url = url.replace(":".concat(param), partOfURL);
      if ((resource.keepData || []).indexOf(param) === -1) {
        options.body = (0, _fp.omit)(options.body, param);
      }
    });
    if (options.body && !Object.keys(options.body).length) {
      options.body = null;
    }
    return options;
  };
  _exports.replaceParamsToValues = replaceParamsToValues;
  const memoize = new _memoize.default();

  /**
   * @module Services/ApiService
   * @prop {Boolean} redirectToLandingPage
   * @prop {Boolean} contractMode
   * @prop {String} authorizationToken
   */
  let ApiService = (_class = (_temp = class ApiService extends Ember.Service.extend(Ember.Evented, (0, _myadtHelpers.clearCache)({
    redirectToLandingPage: false
  })) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "config", _descriptor2, this);
      _initializerDefineProperty(this, "isChudly", _descriptor3, this);
      _initializerDefineProperty(this, "authorizationToken", _descriptor4, this);
      _initializerDefineProperty(this, "redirectToLandingPage", _descriptor5, this);
      _initializerDefineProperty(this, "contractMode", _descriptor6, this);
      _initializerDefineProperty(this, "contractDelay", _descriptor7, this);
      _initializerDefineProperty(this, "contractMode", _descriptor8, this);
      _initializerDefineProperty(this, "windowRedirect", _descriptor9, this);
    }
    init() {
      super.init(...arguments);
      Ember.set(this, 'myADT', makeApiMethod(_httpResources.myADT, this));
      Ember.set(this, 'chudly', makeApiMethod(_httpResources.chudly, this));
      Ember.set(this, 'combined', makeApiMethod(_httpResources.combined, this));
      if (this.config.enableContractModeSwitch) {
        window.contractModeSwitch = contractModeSwitch.bind(this);
        const cmMode = Number(window.sessionStorage.getItem('contractMode')) || this.config.contractMode;
        window.contractMode = Ember.set(this, 'contractMode', cmMode);
      } else {
        window.contractModeSwitch = function () {
          return {};
        };
      }
    }

    /**
     * Executes `jQuery.get` with passed parameters.
     * @instance
     * @param {String} url
     * @param {Boolean} [showLoading]
     * @return {ApiResponse}
     */
    getJSON(url) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const {
          contractMode
        } = this;
        let success = false;
        let status;
        (0, _fetch.fetch)("/".concat(url)).then(response => {
          status = response.status;
          success = status >= 200 && status < 300;
          return response._bodyBlob.type === 'application/json' ? response.json() : response.text();
        }).then(response => {
          const results = this.handleAjaxResponse(response, {
            url,
            contractMode
          }, success, status);
          if (results.success) {
            Ember.run(null, resolve, results);
          } else {
            Ember.run(null, reject, results);
          }
        }).catch(response => {
          const isJson = response._bodyBlob.type === 'application/json';
          const xhr = isJson ? response.json() : response.text();
          const results = this.handleAjaxResponse(xhr, {
            url,
            contractMode
          }, success, response.status);
          Ember.run(null, reject, results);
        });
      });
    }

    /**
     * Standard API request, used by all API methods that reach
     * '/api/' URLs
     * @instance
     * @param {Object} options
     * @param {Object} options.resource
     * @param {String} options.resource.url request url
     * @param {String} options.resource.method request method
     * @param {String[]} [options.resource.params] array of variable url parameters; matching key must be passed in options.params
     * @param {String} [options.resource.contract] contract url for contract mode
     * @param {Object} [options.params] key value pairs of request parameters; key must be in options.resource.params to be used
     * @param {Boolean} [options.redirectToLandingPage=false] should execute unauthRedirect if return 401/403/405
     * @param {Boolean} [options.checkResponseHeaders=true] check for unauth response
     * @param {Boolean} [options.cache=false]
     * @param {Boolean} [options.contractMode]
     * @param {Object|String} [options.body] data to post or send as query string
     * @param {Object|String} [options.data] deprecated | data to post or send as query string
     * @param {String} [options.dataType='json'] data type to expect
     * @param {String} [options.contentType='application/json; charset=utf-8']
     * @param {Object} [options.headers]
     * @return {ApiResponse}
     */
    request(opts) {
      let success = false;
      const options = (0, _fp.omix)(opts, {
        redirectToLandingPage: false,
        checkResponseHeaders: true,
        showLoading: true,
        cache: false,
        contract: '',
        contractMode: this.contractMode,
        url: ''
      });
      let fetchRequest = {
        body: opts.body || opts.data,
        params: opts.params,
        headers: (0, _fp.omix)({
          // client_id: this.isChudly ? 'chudlyWeb' : 'myadtWeb',
          searchId: typeof sessionStorage !== 'undefined' ? sessionStorage.chudlySearchId || null : null,
          'Content-Type': opts.contentType || 'application/json',
          Accept: 'application/json, text/*, */*'
        }, opts.headers),
        credentials: 'include',
        mode: 'cors',
        method: options.resource.method || POST
      };

      // this function mutate data
      fetchRequest = (0, _fp.omix)(fetchRequest, replaceParamsToValues(options.resource, fetchRequest));
      if (fetchRequest.method === POST) {
        fetchRequest.body = JSON.stringify(fetchRequest.body);
      }
      if (opts.contractMode || this.contractMode) {
        const contract = opts.resource.contract || "".concat(opts.resource.url.replace(/\//g, '.'), ".json");
        // API.contractMode.showContractorButton();
        fetchRequest.url = "/contracts/".concat(contract);

        // ===== MUTABLE CONTRACTS FOR CHUDLY =====
        // if (window.localStorage && contract) {
        // 	let contractName = window.localStorage.getItem(contract);
        // 	if (contractName && contractName !== contract) {
        // 		options.url = '/contracts/' + contractName;
        // 	}
        // }
      } else {
        fetchRequest.url = this.config.apiURL + fetchRequest.url;
      }
      if (memoize.check(options)) {
        return memoize.fromCache(options);
      }
      const promise = new Ember.RSVP.Promise((resolve, reject) => {
        let status;
        (0, _fetch.fetch)(fetchRequest.url, fetchRequest).then(response => {
          status = response.status;
          success = status >= 200 && status < 300;
          this.authorizationToken = response.headers.map.authorization || null;
          const isJson = response._bodyBlob.type === 'application/json';
          return isJson ? response.json() : response.text();
        }).then(response => this.handleAjaxResponse(response, options, success, status)).then(results => results.success ? Ember.run(null, resolve, results) : Ember.run(null, reject, results)).catch(response => {
          let results;
          if (response instanceof _ajaxResponse.default) {
            results = response;
          } else if (response instanceof ReferenceError) {
            console.log(response);
            const {
              name,
              message,
              filename,
              lineNumber,
              stack
            } = response;
            const xhr = {
              success: false,
              errors: ["".concat(name, ": ").concat(message, " (").concat(filename, ":").concat(lineNumber, ")")],
              messages: [stack],
              data: null
            };
            results = this.handleAjaxResponse(xhr, false, 500);
          } else if (response._bodyBlob) {
            const xhr = response._bodyBlob.type === 'application/json' ? response.json() : response.text();
            results = this.handleAjaxResponse(xhr, options, success, response.status);
            if (options.checkResponseHeaders) {
              this.checkResponseHeaders(xhr.status, options);
            }
          } else {
            console.error(response);
          }
          Ember.run(null, reject, results);
        });
      });
      memoize.save(options, promise);
      return promise;
    }

    /**
     * Pure AJAX request which wrapped by promise.
     * @param {Object} params
     * @returns {Promise}
     */
    pureRequest(params = {}) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        let success = false;
        return (0, _fetch.fetch)(params.url, params).then(async response => {
          success = response.status === 200;
          const isJson = response._bodyBlob.type === 'application/json';
          return isJson ? response.json() : response.text();
        }).then(out => success ? resolve(out) : reject(out)).catch(out => reject(out));
      });
    }

    /**
     * Filters for contract mode and creates new {@link Classes/AjaxResponse}
     * @param {String|Object} response
     * @param {Object} fetchOptions original request options
     * @returns {AjaxResponse}
     */
    handleAjaxResponse(response, fetchOptions, success, status) {
      let resp = fetchOptions.contractMode && response.response ? response.response : response;
      if (fetchOptions.contractMode && response.dataKitName && response.dataKits) {
        const kit = response.dataKits[response.dataKitName];
        if (kit) {
          resp = (0, _fp.omix)(resp, kit);
        }
      }
      return new _ajaxResponse.default(resp, status, success);
    }

    /**
     * Checks headers for unauthorized access and redirects as necessary
     * @param {String} status XHR status
     * @param {Object} fetchOptions original request options
     */
    checkResponseHeaders(status, fetchOptions) {
      // Check for unauthorized
      if (status === 401 || status === 403 || status === 407) {
        // Support Ajax Calls not using API
        const isRequestANDGO = fetchOptions && fetchOptions.redirectToLandingPage;
        const isNotRequestANDGO = !fetchOptions && this.redirectToLandingPage;
        if (isRequestANDGO || isNotRequestANDGO) {
          this.unauthRedirect();
          return false;
        }
      }
      return Ember.set(this, 'redirectToLandingPage', true);
    }

    /**
     * Redirects to index route if response is 401, 403, or 407
     */
    unauthRedirect() {
      /* eslint-disable no-console */
      console.warn('Method `unauthRedirect` should be overloaded');
      /* eslint-enable no-console */
      return Ember.RSVP.Promise.resolve();
    }
  }, _temp), _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [Ember.inject.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "config", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        enableContractModeSwitch: true
      };
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isChudly", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "authorizationToken", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "redirectToLandingPage", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "contractMode", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.computed.alias('config.contractMode');
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "contractDelay", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.computed.alias('config.contractDelay');
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "contractMode", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "windowRedirect", [Ember._tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return _myadtHelpers.windowRedirect;
    }
  }), _class);
  _exports.default = ApiService;
});